<template>
    <div>
        <title-bar :title="$t('navigations.stockItemLocations')"/>
        <div v-if="rows" class="custom-table">
            <table-actions
                :actions="['search', 'addBtn', 'perPage']"
                :addBtnText="$t('messages.stockItemLocationsAdd')"
                :addBtnLink="$helper.getAddUrl('storage-locations')"
                @onSearchInput="inputChange"
                @perPageChange="perPageChange"
                :defaultPerPage="perPage"
            />
            <table-lite
                :is-slot-mode="true"
                :is-loading="isLoading"
                :columns="columns"
                :rows="rows"
                :sortable="sortable"
                @do-search="setSort"
                @is-finished="isLoading = false"
            >
                <template v-slot:station="data">
          <span v-if="data.value.station">
            {{
                  data.value.station.name
              }}
          </span>
                </template>
                <template v-slot:stockItemLocation="data">
                    {{ data.value.stockItemLocation }}
                </template>
                <template v-slot:actions="data">
                    <div class="just-buttons">
                        <edit-btn
                            :path="$helper.getEditUrl('storage-locations', data.value.id)"
                        />
                        <delete-btn @pressDelete="deleteStockItemLocation(data.value['@id'])"/>
                    </div>
                </template>
            </table-lite>
            <table-pagination
                v-model="currentPage"
                :total="pages"
                :per-page="perPage"
                @input="onChangePage"
            />
        </div>
    </div>
</template>

<script>

import TitleBar from "@/components/TitleBar.vue";
import TableLite from "vue3-table-lite";
import TablePagination from "@/components/Table/TablePagination.vue";
import TableActions from "@/components/Table/TableActions.vue";
import ResourceUtils from "@/mixins/ResourceUtils";
import EditBtn from "@/components/Buttons/EditBtn.vue";
import DeleteBtn from "@/components/Buttons/DeleteBtn.vue";

export default {
    name: "AdminStorageLocations",
    components: {
        TableLite,
        TablePagination,
        TableActions,
        TitleBar,
        EditBtn,
        DeleteBtn
    },
    mixins: [ResourceUtils],
    data() {
        return {
            search: "",
            isLoading: false,
            rows: null,
            perPage: this.$store.state.defaultPerPage,
            currentPage: 1,
            pages: 0,
            sortable: {
                order: "station",
                sort: "asc",
            },
            columns: [
                {
                    label: this.$t("forms.station"),
                    field: "station",
                    sortable: true,
                },
                {
                    label: this.$t("forms.stockItemLocation"),
                    field: "stockItemLocation",
                    sortable: false,
                },
                {
                    label: this.$t("forms.actions"),
                    field: "actions",
                    sortable: false,
                    width: "80px",
                },
            ],
        };
    },
    created() {
        this.load();
    },
    methods: {
        load() {
            const params = {
                itemsPerPage: this.perPage,
                page: this.currentPage,
            };
            params["order[" + this.sortable.order + "]"] = this.sortable.sort;
            if (this.search && this.search.length) {
                params.stockItemLocation = this.search;
            }
            this.$StockItemLocations.getCollection({params}).then((response) => {
                this.pages = response.data["hydra:totalItems"];
                this.rows = response.data["hydra:member"];
            });
        },
        setSort(offset, limit, order, sort) {
            this.sortable.order = order;
            this.sortable.sort = sort;
            if (order === 'station') {
                this.sortable.order = order + '.name';
            }
            this.load();
        },
        onChangePage(page) {
            this.currentPage = page;
            this.load();
        },
        inputChange(value) {
            this.currentPage = 1;
            this.search = value;
            this.load();
        },
        onPageChange() {
            this.load();
        },
        deleteStockItemLocation(url) {
            this.deleteByUrl(
                this.$StockItemLocations,
                url,
                this.$t("messages.stockItemLocationDeleted"),
                null,
                this.load,
                this.error
            );
        },
        perPageChange(num) {
            this.currentPage = 1;
            this.perPage = num;
            this.load();
        },
        error() {
            this.$emit("clearAction");
        },
    },
};
</script>
